@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fichier CSS principal (index.css ou App.css) */

/* Le conteneur qui gère le scroll-snap pour les sections */
.scroll-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  scroll-padding: 10px;
}

/* Chaque section individuelle avec snap */
.container-snap {
  height: 100vh;
  scroll-snap-align: start;
}

.scroll-container {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 100vh;
}
/* Sections individuelles */
.container-snap {
  height: 100vh;
  scroll-snap-align: start;
}

.gradient-ctn {
  background: linear-gradient(to right, #cc133e 50%, transparent 50%);
}

.footer{
  scroll-snap-stop: always;
  scroll-snap-align: start;
}

.red-gradient {
  background: linear-gradient(to right, #ED8989, #980023);
}

.react-toggle .react-toggle-track {
  background-color: #fff;
  border:1px solid #cc133e; /* Couleur de base */
}

.react-toggle:hover .react-toggle-track {
  background-color: #cc133e; /* Couleur au survol */
}

.react-toggle.react-toggle--checked .react-toggle-track {
  background-color: #cc133e; /* Couleur lorsqu'il est activé */
}

.react-toggle.react-toggle--checked:hover .react-toggle-track {
  background-color: #cc133e; /* Couleur lorsqu'il est activé et survolé */
}

/*SCROLLBAR*/

/* Style de la barre de défilement pour les navigateurs compatibles */
::-webkit-scrollbar {
  width: 8px; /* Largeur de la scrollbar */
  height: 0px;
}

::-webkit-scrollbar-track {
  background: black; /* Fond transparent */
}

::-webkit-scrollbar-thumb {
  background-color: #cc133e; /* Couleur du slider (partie mobile) */
  border-radius: 4px; /* Arrondi pour un effet plus moderne */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.8); /* Changement de couleur au survol */
}

.text-giga-small{
  font-size: 1px;
}